/* eslint-disable */
const caseStudy = (function () {

	if (!$(".new-template-case-study").length) {
		return;
	}

	const container = $(".new-template-case-study .container");
	const challengeContent = $(".challenge.challenge--option-1 .container");

	const formSection = $("#section-case-study");
	const btnDownloadCaseStudy = $(".new-template-case-study .btn-download-case-study");
	const btnDownloadCaseStudy_new = $(".btn-download-case-study_new");

	let setup = function () {
		if (challengeContent) {
			challengeContent.css("padding-left", container.css("margin-left"));
		}
	};

	let setEvents = function () {
		btnDownloadCaseStudy.on("click", function (event) {
			event.preventDefault();
			let formSetionOffsetTop = formSection.offset().top - 52;
			window.scroll({
				top: formSetionOffsetTop,
				behavior: 'smooth'
			});
		});
		btnDownloadCaseStudy_new.on("click", function (event) {
			event.preventDefault();
			let formSetionOffsetTop = formSection.offset().top - 52;
			window.scroll({
				top: formSetionOffsetTop,
				behavior: 'smooth'
			});
		});
	};

	let init = function () {
		setup();
		setEvents();
	};

	return {
		init: init,
	}
})();

$(document).ready(function () {
	if ($(".new-template-case-study").length) {
		$("body").addClass('body-new-case-study');
		caseStudy.init();
	} else {
		$("body").removeClass('body-new-case-study');
	}

	$(".share-list-new").jsSocials({
		shareIn: "popup",
		shares: ["email", "linkedin", "twitter", "facebook"]
	});

	myString = $(document).find("title").text();
	myString = myString.replace(' | Fractal', '');
	$('#case_study_title').val(myString);

	//pov case study
	$('.dtc-dx .video-thumbnail .icon-play').magnificPopup({
		disableOn: 300,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160
	});
});


$(window).on("resize", function () {
	if ($(".new-template-case-study").length) {
		caseStudy.init();
	}
});

$(".board-block.board-block--custom .leadership_read_more").on("click", function (e) {
	e.preventDefault(),$(this).parent().parent().parent().find(".advisory_media").show(), $(this).parent().parent().parent().find(".advisory_media").addClass('active');
}),$(".advisory_media .clickdismis").on("click", function (e) {
	e.preventDefault(), $(this).parent().parent().parent().find(".advisory_media").hide();
});

$(window).on("load", function () {
	setTimeout(function(){
		if ($(window).width() >= 767) {
			var e = -1;
			$(".case-study-card .card-info-box.card-new-hight").height('');
			$(".case-study-card .card-info-box.card-new-hight").each(function () {
				e = e > $(this).outerHeight() ? e : $(this).outerHeight()
			}), $(".case-study-card .card-info-box.card-new-hight").each(function () {
				$(this).height(e)
			})
		}
	},2000);
});
