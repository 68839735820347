import Swiper from "swiper/bundle";

let economist = (() => {
	let galleryInit = () => {
		let galleryThumbs = null;
		let galleryTop = null;

		// thumbnails
		galleryThumbs = new Swiper(".gallery-thumbs-wrapper .gallery-thumbs", {
			spaceBetween: 12,
			slidesPerView: 2.25,
			loop: true,
			freeMode: true,
			loopedSlides: 5, //looped slides should be the same
			watchSlidesVisibility: true,
			watchSlidesProgress: true,
			navigation: {
				nextEl: "#thumb-next",
				prevEl: "#thumb-prev",
			},
			breakpoints: {
				640: {
					slidesPerView: 4,
					spaceBetween: 14,
				},
			},
		});

		// thumbnail preview
		galleryTop = new Swiper(".gallery-top", {
			spaceBetween: 0,
			loop: true,
			loopedSlides: 5, //looped slides should be the same
			autoplay: {
				delay: 5000,
			},
			thumbs: {
				swiper: galleryThumbs,
			},
			navigation: {
				nextEl: "#gallery-top-thumb-next",
				prevEl: "#gallery-top-thumb-prev",
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* recongnition */
let recongnition = (() => {
	let galleryInit = () => {
		let logoesGallery = null;

		// thumbnail preview
		logoesGallery = new Swiper(".logoes-gallery", {
			spaceBetween: 30,
			loop: false,
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				640: {
					slidesPerView: 5,
				},
			},
			autoplay: {
				delay: 5000,
			},
			navigation: {
				nextEl: "#logoes-gallery-next",
				prevEl: "#logoes-gallery-prev",
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* aed */
let aed = (() => {
	let galleryInit = () => {
		let logoesGallery = null;

		// thumbnail preview
		logoesGallery = new Swiper(".videos", {
			spaceBetween: 30,
			loop: false,
			breakpoints: {
				320: {
					slidesPerView: 1,
				},
				768: {
					slidesPerView: 2,
				},
				991: {
					slidesPerView: 3,
				},
			},
			autoplay: {
				delay: 5000,
			},
			navigation: {
				nextEl: "#videos-next",
				prevEl: "#videos-prev",
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* business delivering */
let business_delivering = (() => {
	let galleryInit = () => {
		let logoesGallery = null;

		// thumbnail preview
		logoesGallery = new Swiper(".business-info .swiper-container", {
			loop: false,
			autoHeight: true,
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 0,
				},
				768: {
					slidesPerView: 3,
					spaceBetween: 20,
				},
			},
			pagination: {
				el: ".info-slider .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
			autoplay: {
				delay: 10000,
			},
			disableOnInteraction: true,
			navigation: {
				nextEl: "#business-info-next",
				prevEl: "#business-info-prev",
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* cab member */
let cabMember = (() => {
	let cabSlider1, cabSlider2, cabSlider3;
	let isStoped = false;

	let device = {
		tablet: /iPad/i.test(navigator.userAgent),
	};

	let galleryInit = () => {
		cabSlider1 = new Swiper(".cab-member .slider-1", {
			transitionEnd: false,
			slidesPerView: 3,
			spaceBetween: 20,
			loop: true,

			//autoplay: true,

			autoplay: {
				delay: 500,
			},
			speed: 2000,
			grabCursor: false,
			navigation: {
				nextEl: ".cab-member  .swiper-button-next",
				prevEl: ".cab-member  .swiper-button-prev",
			},
		});

		cabSlider2 = new Swiper(".cab-member .slider-2", {
			transitionEnd: false,
			slidesPerView: 3,
			spaceBetween: 20,
			loop: true,

			//autoplay: false,

			autoplay: {
				delay: 700,
			},
			speed: 2000,
			grabCursor: false,
			navigation: {
				nextEl: ".cab-member  .swiper-button-next",
				prevEl: ".cab-member  .swiper-button-prev",
			},
		});

		cabSlider3 = new Swiper(".cab-member .slider-3", {
			transitionEnd: false,
			slidesPerView: 3,
			spaceBetween: 20,
			loop: true,

			//autoplay: true,

			autoplay: {
				delay: 500,
			},
			speed: 2000,
			grabCursor: false,
			navigation: {
				nextEl: ".cab-member .swiper-button-next",
				prevEl: ".cab-member .swiper-button-prev",
			},
		});

		/* mobile slider */
		let cabSliderMobile1 = new Swiper(".cab-member .slider-mobile-1", {
			slidesPerView: 2.5,
			spaceBetween: 16,
			loop: true,
			autoplay: {
				delay: 2000,
				disableOnInteraction: true,
			},
			speed: 1000,
			navigation: {
				nextEl: ".cab-member .cab-member--mobile .swiper-button-next",
				prevEl: ".cab-member .cab-member--mobile .swiper-button-prev",
			},
		});

		let cabSliderMobile2 = new Swiper(".cab-member .slider-mobile-2", {
			slidesPerView: 2.5,
			spaceBetween: 16,
			loop: true,
			autoplay: {
				delay: 2000,
				disableOnInteraction: true,
			},
			speed: 1000,
			navigation: {
				nextEl: ".cab-member .cab-member--mobile .swiper-button-next",
				prevEl: ".cab-member .cab-member--mobile .swiper-button-prev",
			},
		});

		// cab highlight slider
		let cabHighLightSlider = new Swiper(
			".cab-highlight .swiper-container",
			{
				loop: false,
				speed: 1000,
				autoplay: {
					delay: 5000,
				},
				breakpoints: {
					320: {
						slidesPerView: 1.2,
						slidesPerGroup: 1,
						spaceBetween: 16,
					},
					768: {
						slidesPerView: 2,
						slidesPerGroup: 2,
						spaceBetween: 16,
					},
				},
				navigation: {
					nextEl: ".cab-highlight .swiper-button-next",
					prevEl: ".cab-highlight .swiper-button-prev",
				},
			}
		);
	};

	let onTransitionEnd = function () {
		cabSlider1.on("transitionEnd", function () {
			cabSlider2.autoplay.start();
			cabSlider1.autoplay.stop();
		});
		cabSlider3.on("transitionEnd", function () {
			cabSlider3.autoplay.stop();
		});
		cabSlider2.on("transitionEnd", function () {
			cabSlider2.autoplay.stop();
			cabSlider1.autoplay.start();
			cabSlider3.autoplay.start();
		});
	};

	let init = () => {
		galleryInit();

		//onTransitionEnd();
	};

	/* stop the sliders & slide for next section */
	$(
		".cab-member .swiper-button-next, .cab-member  .swiper-button-prev"
	).click(function () {
		/* eslint-disable */
		if (device.tablet) {
			if (isStoped === false) {
				cabSlider1.autoplay.stop();
				cabSlider2.autoplay.stop();
				cabSlider3.autoplay.stop();
				console.log("slider stopped");

				/* 	setTimeout(function () {
						cabSlider1.slideNext()
						cabSlider2.slideNext()
						cabSlider3.slideNext()
					}, 2150); */
			}
			isStoped = true;
		}
	});

	// speed
	$(".cab-member .swiper-button-next").click(function () {
		cabSlider1.slideNext();
		cabSlider2.slideNext();
		cabSlider3.slideNext();
	});
	$(".cab-member .swiper-button-prev").click(function () {
		cabSlider1.slidePrev();
		cabSlider2.slidePrev();
		cabSlider3.slidePrev();
	});

	$(".cab-member").mouseenter(function () {
		cabSlider1.autoplay.stop();
		cabSlider2.autoplay.stop();
		cabSlider3.autoplay.stop();
	});
	$(".cab-member").mouseleave(function () {
		cabSlider1.autoplay.start();
		cabSlider2.autoplay.start();
		cabSlider3.autoplay.start();

		/* cabSlider1.autoplay.stop();
		cabSlider2.autoplay.start();
		cabSlider3.autoplay.stop(); */

		/* cabSlider1.update();
		cabSlider2.update();
		cabSlider3.update(); */

		/* cabSlider1.updateSize();
		cabSlider2.updateSize();
		cabSlider3.updateSize(); */

		/* cabSlider1.updateSlides();
		cabSlider2.updateSlides();
		cabSlider3.updateSlides(); */
	});

	return {
		init: init,
	};
})();

/* New aed */
let aedNew = (() => {
	let swiper;

	let galleryInit = () => {
		swiper = new Swiper(".problem-solve-slider", {
			slidesPerView: 1,
			spaceBetween: 40,
			loop: true,
			autoplay: {
				delay: 8000,
			},
			speed: 2000,
			pagination: {
				el: ".problem-solve-slider .swiper-pagination",
				clickable: true,
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* pov case study */
let povCaseStudy = (() => {
	let galleryInit = () => {
		let swiper = new Swiper(".dtc-dx .swiper-container", {
			loop: true,
			autoplay: {
				delay: 4000,
			},
			speed: 1000,
			breakpoints: {
				320: {
					slidesPerView: 1.5,
					spaceBetween: 16,
				},
				991: {
					slidesPerView: 2.22,
					spaceBetween: 32,
				},
			},
			navigation: {
				nextEl: ".dtc-dx .swiper-button-next",
				prevEl: ".dtc-dx .swiper-button-prev",
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* Awards and Recognition */
let awardsAndRecog = (() => {
	let galleryInit = () => {
		let swiper = new Swiper(".awards-recognition-slider", {
			loop: true,
			// autoplay: {
			// 	delay: 4000,
			// },
			speed: 1000,
			breakpoints: {
				1280: {
					slidesPerView: 1,
					// spaceBetween: 38,
				},
			},
			pagination: {
				el: ".awards-recognition-slider .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();
let awardsAndRecogMobile = (() => {
	let galleryInit = () => {
		let swiper = new Swiper(".awards-recognition-slider-mobile", {
			loop: true,
			// autoplay: {
			// 	delay: 4000,
			// },
			speed: 1000,
			breakpoints: {
				320: {
					slidesPerView: 1.3,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: 2.3,
					spaceBetween: 24,
				},
			},
			pagination: {
				el: ".awards-recognition-slider-mobile .swiper-pagination",
				type: "bullets",
				clickable: true,
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();
/* pov07 */
let pov07 = (() => {
	let galleryInit = () => {
		let swiperRetailExp = new Swiper(".retail-exp .swiper-container", {
			pagination: {
				el: ".retail-exp .swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 9,
				},
			},
		});
		let swiperNonBinaryFuture = new Swiper(
			".non-binary-future .swiper-container",
			{
				pagination: {
					el: ".non-binary-future .swiper-pagination",
					clickable: true,
				},
			}
		);
		let swiperYouWillEee = new Swiper(".you-will-see .swiper-container", {
			pagination: {
				el: ".you-will-see .swiper-pagination",
				clickable: true,
			},
			breakpoints: {
				320: {
					slidesPerView: 1,
					spaceBetween: 16,
				},
				768: {
					slidesPerView: 1.25,
					spaceBetween: 32,
				},
				1024: {
					slidesPerView: 2.25,
					spaceBetween: 32,
				},
			},
		});
	};

	let init = () => {
		galleryInit();
	};

	return {
		init: init,
	};
})();

/* initilizing economist  */
window.addEventListener("load", () => {
	economist.init();
	recongnition.init();
	aed.init();
	business_delivering.init();
	cabMember.init();
	aedNew.init();
	povCaseStudy.init();
	awardsAndRecog.init();
	awardsAndRecogMobile.init();
	pov07.init();

	$("a.share-wrap-click").trigger("click");
	setTimeout(function () {
		$(".ritu").height("auto");
		$(".member-popup .designation").height("auto");
		$(".member-popup .designation").css("min-height", "35px");
	}, 3000);

	var e = $(".container").offset().left + 15;

	//owl slider for ainyc2019 page
	$(".owl-carousel.event2").owlCarousel({
		center: !1,
		items: 2,
		nav: !0,
		autoplay: false,
		stagePadding: e,
		smartSpeed: 1e3,
		navText: [" ", " "],
		margin: 30,
		responsive: { 0: { items: 1 }, 767: { items: 2 }, 992: { items: 3 } },
	});
	var t = ($(".container"), $(".container").offset().left - 15);
	$(".owl-nav .owl-prev").css("left", t);
	$(".owl-nav .owl-next").css("right", t);

	//if ($(window).width() >= 768) {
	var e = -1;
	$(".rt-video-post-feature .owl-item .inner.cab-agenda").each(function () {
		e = e > $(this).height() ? e : $(this).height();
	}),
		$(".rt-video-post-feature .owl-item .inner.cab-agenda").each(
			function () {
				$(this).height(e);
			}
		);
	//}
});

/* page social share  */
$(".share-list").jsSocials({
	shares: ["linkedin", "twitter", "facebook"],
});
