/* eslint-disable */
// do not remove above comment

const careOfCab = (function () {

	const handleActiveStateOfTab = function () {

		// variables
		let intervalAtCab, step = 0;
		let active = 'active';
		const tabs = $('.at-cab--desktop .tab');
		const tabsLength = tabs.length;
		const tabDetails = $('.at-cab--desktop .tab-details');

		// event handler
		tabs.on('mouseover click', function () {

			// need to clear interval if user hover or clicked on tab
			clearInterval(intervalAtCab);

			$(tabs).removeClass(active); // reset
			$(this).addClass(active); // add active class on current item

			// get index of current item
			const indexForTabDetails = $(tabs).index(this);
			tabDetails.hide(); //reset
			tabDetails.eq(indexForTabDetails).show(); // add show class to matching details section

		});

		// update active state of tab & show matching details section
		function increaseStep() {

			if (step === tabsLength) {
				step = 0; // reset
			} else {
				$(tabs).removeClass(active);
				$(tabs).eq(step).addClass(active);
				step++;
			}

			// show matching details section
			tabs.each(function () {
				let currentEle = $(this), activeEleIndex;

				if (currentEle.hasClass(active)) {
					activeEleIndex = $(tabs).index(currentEle); // get current active item index
					tabDetails.hide(); // reset
					tabDetails.eq(activeEleIndex).show();
				}
			});
		}

		// call each time after perticular delay
		intervalAtCab = setInterval(increaseStep, 2000);

	}

	// accordion
	const accourdionMobile = function () {

		// variables
		const panelDefault = $('.panel-default');
		const panelCollapse = $('.panel-collapse');
		const triggerEle = $('.panel-heading > a');

		const active = 'active';
		triggerEle.on('click', function () {
			const isActive = $(this).parent('.panel-heading').parent(panelDefault).hasClass(active);
			if (isActive) {
				$(panelDefault).removeClass(active);
			} else {
				$(panelDefault).removeClass(active);
				$(this).parent('.panel-heading').parent(panelDefault).addClass(active);
			}

		});
	}

	return {
		handleActiveStateOfTab: handleActiveStateOfTab,
		accourdionMobile: accourdionMobile
	}
})();

$(document).ready(function () {

	// return if this is not a cab 2021 section
	const mainParent = $('.core-of-cab-page');
	if (!mainParent) return;

	careOfCab.handleActiveStateOfTab();
	careOfCab.accourdionMobile();

	$(".cab-member .swiper-slide a").on("click", function (event) {
		event.preventDefault();
	});

	$('.cab-highlight__content .icon-play').magnificPopup({
		disableOn: 300,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160
	});

});
