/* eslint-disable */
const navbar = (function () {

	const isActiveCls = 'is-active';
	const open = 'open';

	let body = $("body");
	let navV2 = $("#navigation-v2");
	let navigationMobile = $('.navigation-v2--mobile');
	let logoContainer = $('.navigation-v2-mobile-header .logo-container');
	let btnHamburger = $('.navigation-v2-mobile-header #btn-hamburger');
	let btnHamburgerClose = $('.navigation-v2-mobile-header #btn-hamburger-close');
	let navWrapper = $('.navigation-v2--mobile .nav-wrapper');
	let navListItem = $('.navigation-v2--mobile .nav__list li');
	let backBtn = $('.navigation-v2--mobile .sub-menus__header');
	let subMenu = $('.navigation-v2--mobile .sub-menus');
	let subMenuItemList = $('.navigation-v2--mobile .sub-menus .sub-menus__list li');

	// mobile navbar
	let mobileMenu = function () {
		let level = 0;
		btnHamburger.on('click', function () {
			if ($('ul.sub-menus__list li').hasClass('active')) {
				$('ul.sub-menus__list li.active').closest('ul').closest('li').addClass('is-active').closest('ul').closest('li').addClass('is-active');
			}
			level = navWrapper.attr("data-level");
			navWrapper.attr("data-level", level);
			logoContainer.addClass(open);
			body.css("position", "fixed");
			navigationMobile.addClass(open);
			navV2.addClass(open);
		});
		btnHamburgerClose.on('click', function () {
			reset();
		});

		navListItem.on('click', function () {
			const hasDropDown = $(this).hasClass('has-dropdown');
			if (hasDropDown) {
				$(this).addClass(isActiveCls);
				navWrapper.attr("data-level", ++level);
			}
		});
		backBtn.on('click', function (event) {
			event.stopPropagation();
			const isActive = $(this).closest("li").hasClass(isActiveCls);
			if (isActive) {
				$(this).closest("li").removeClass(isActiveCls);
				navWrapper.attr("data-level", --level);
			}
		});
		subMenu.on('click', function (event) {
			event.preventDefault();
			event.stopPropagation();
		});
		subMenuItemList.on('click', function (event) {
			event.stopPropagation();
		});
		//reset
		function reset() {
			if ($('ul.sub-menus__list li.active').attr("data-level")) {
				level = $('ul.sub-menus__list li.active').attr("data-level");
			} else {
				level = 0;
			}
			console.log(level + 'dfdkjhfkdjh');
			navWrapper.attr("data-level", level);
			navListItem.removeClass(isActiveCls);
			backBtn.removeClass(isActiveCls);
			navV2.removeClass(open);
			navigationMobile.removeClass(open);
			logoContainer.removeClass(open);
			subMenuItemList.find('a.active').parents('li').addClass(isActiveCls);
			body.css("position", "static");
		}

		// reset menu state
		(function resetMenuState() {
			level = $('ul.sub-menus__list li.active').attr("data-level");
			if ($('ul.sub-menus__list li').hasClass('active')) {
				console.log('reset2');
				$('ul.sub-menus__list li.active').closest('ul').closest('li').addClass('is-active').closest('ul').closest('li').addClass('is-active');
			}
			navWrapper.attr("data-level", level);
		})();
	};

	// desktop navbar
	let desktopMenu = function () {
		let menuItems = [].slice.call(document.querySelectorAll('.menu__item')),
			menuSubs = [].slice.call(document.querySelectorAll('.dropdown-menu-v3')),
			selectedMenu = undefined,
			subBg = document.querySelector('.dropdown__bg'),
			subBgBtm = document.querySelector('.dropdown__bg-bottom'),
			subArr = document.querySelector('.dropdown__arrow'),
			subCnt = document.querySelector('.dropdown__wrap'),
			header = document.querySelector('.main-header'),
			closeDropdownTimeout,

			startCloseTimeout = function () {
				closeDropdownTimeout = setTimeout(() => closeDropdown(), 50);
			},

			stopCloseTimeout = function () {
				clearTimeout(closeDropdownTimeout);
			},

			openDropdown = function (el) {

				//- get menu ID
				let menuId = el.getAttribute('data-sub');
				//- get related sub menu
				let menuSub = document.querySelector('.dropdown-menu-v3[data-sub="' + menuId + '"]');
				//- get menu sub content
				let menuSubCnt = menuSub.querySelector('.dropdown-menu-v3__content');
				//- get bottom section of current sub
				// let menuSubBtm = menuSubCnt.querySelector('.bottom-section').getBoundingClientRect();
				//- get height of top section
				let menuSubTop = menuSubCnt.querySelector('.top-section').getBoundingClientRect();
				//- get menu position
				let menuMeta = el.getBoundingClientRect();
				//- get sub menu position
				let subMeta = menuSubCnt.getBoundingClientRect();


				//- set selected menu
				selectedMenu = menuId;


				//- Remove active Menu
				menuItems.forEach(el => el.classList.remove('active'));
				//- Set current menu to active
				el.classList.add('active');

				//- Remove active sub menu
				menuSubs.forEach(el => el.classList.remove('active'));
				//- Set current menu to active
				menuSub.classList.add('active');

				//- Set dropdown menu background style to match current submenu style
				subBg.style.opacity = 1;
				subBg.style.left = menuMeta.left - (subMeta.width / 2 - menuMeta.width / 2) + 'px';
				subBg.style.width = subMeta.width + 'px';
				subBg.style.height = subMeta.height + 'px';
				//- Set dropdown menu bottom section background position
				subBgBtm.style.top = menuSubTop.height + 'px';
				// console.log(menuSubBtm);

				//- Set Arrow position
				subArr.style.opacity = 1;
				subArr.style.left = menuMeta.left + menuMeta.width / 2 - 10 + 'px';

				//- Set sub menu style
				subCnt.style.opacity = 1;
				subCnt.style.left = menuMeta.left - (subMeta.width / 2 - menuMeta.width / 2) + 'px';
				subCnt.style.width = subMeta.width + 'px';
				subCnt.style.height = subMeta.height + 'px';

				//- Set current sub menu style
				menuSub.style.opacity = 1;

				header.classList.add('dropdown-active');

			},
			closeDropdown = function () {

				//- Remove active class from all menu items
				menuItems.forEach(el => el.classList.remove('active'));
				//- Remove active class from all sub menus
				menuSubs.forEach(el => {
					el.classList.remove('active');
					el.style.opacity = 0;
				});
				//- set sub menu background opacity
				subBg.style.opacity = 0;
				subBg.style.width = '0px';
				subBg.style.height = '0px';
				//- set arrow opacity
				subArr.style.opacity = 0;

				//- Set sub menu style
				subCnt.style.width = '0px';
				subCnt.style.height = '0px';

				// unset selected menu
				selectedMenu = undefined;

				header.classList.remove('dropdown-active');
			};


		//- Binding mouse event to each menu items
		menuItems.forEach(el => {

			//- mouse enter event
			el.addEventListener('mouseenter', function () {
				stopCloseTimeout();
				openDropdown(this);
			}, false);

			//- mouse leave event
			el.addEventListener('mouseleave', () => startCloseTimeout(), false);

		});

		//- Binding mouse event to each sub menus
		menuSubs.forEach(el => {

			el.addEventListener('mouseenter', () => stopCloseTimeout(), false);
			el.addEventListener('mouseleave', () => startCloseTimeout(), false);

		});

	}

	let init = function () {
		mobileMenu();
		desktopMenu();
	}

	return {
		init: init
	};

})();

$(document).ready(function () {
	navbar.init();
	let level = 0;
	//$('ul.sub-menus__list li a.active').parents('li').addClass('is-active');
	if ($('ul.sub-menus__list li').hasClass('active')) {
		$('ul.sub-menus__list li.active').closest('ul').closest('li').addClass('is-active').closest('ul').closest('li').addClass('is-active');
		level = $('ul.sub-menus__list li.active').attr("data-level");
	}
	$('.nav-wrapper').attr("data-level", level);
});
$(window).on('resize', function () {
	navbar.init();
});
var e = $(window).width();
$(window).on("scroll", function () {
	if(767 < $(window).width() && 80 < $(window).scrollTop()) {
		$('.title-sticky-stripe').addClass("titlebar-sticky");
	} else {
		$('.title-sticky-stripe').removeClass("titlebar-sticky");
	}
})
