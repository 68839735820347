/* all devices */
let device = {
	mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test( navigator.userAgent )
};

let prediction = {
	/* eslint-disable */

    //handleScroll: (scrollTop, furtherReadOffsetTop) => {
    handleScroll: (scrollTop) => {

        let persons = $('.persons-wrapper .person');
        let jsTriggers = $('.js-trigger');
        let scrollPosition = 0;

        let predictionTableOuterHeight = $('.prediction-table').outerHeight();

        if (device.mobile) {
            scrollPosition = scrollTop + predictionTableOuterHeight;
        } else {
            scrollPosition = scrollTop + (predictionTableOuterHeight / 2);
        }


        if (100 <= scrollTop) {
            $('#predictions-links li').removeClass('active');
        }


        persons.each(function () {

            let currentElelement = $(this);
            let currentElelementTopPosition = $(this).position().top - 40;

            if (scrollPosition >= currentElelementTopPosition) {

                $('#predictions-links li').removeClass('active'); // reset active class

                currentElelement.addClass('active');

                $('#predictions-links li').eq(currentElelement.index()).addClass('active');

                let hasActiveClass = currentElelement.prev('.person').hasClass('active');
                if (hasActiveClass) {
                    currentElelement.prev().removeClass('active');
                }

            } else {
                currentElelement.removeClass('active');
            }
        });

        jsTriggers.each(function () {
            let currentElelement = $(this);

            // here 40 is the top padding
            let currentElelementTopPosition = $(this).position().top - 40;

            if (scrollPosition > currentElelementTopPosition) {

                currentElelement.addClass('active');

                // reset active class
                $('#content-list > li').removeClass('active');
                $('#content-list > li').eq(currentElelement.index()).addClass('active');

                if (currentElelement.index() === 2) {
                    $('#predictions-links li').removeClass('active');
                }

                let hasActiveClass = currentElelement.prev(jsTriggers).hasClass('active');
                if (hasActiveClass) {
                    currentElelement.prev().removeClass('active');
                }
            } else {
                currentElelement.removeClass('active');
            }
        });

        /* further more */
        // here 100 is height of navbar + prediction-table (mobile) + 10 is an extra adjustment
        // if ((furtherReadOffsetTop - (predictionTableOuterHeight + 110)) < scrollTop) {
        // 	$('#predictions-links li, #content-list > li').removeClass('active');
        // 	$('#content-list > li:last-child').addClass('active');
        // }
    },
    stickyTableContents: (scrollTop, preditionTableOffsetTop) => {

        // mobile purpose
        if (scrollTop > preditionTableOffsetTop) {
            $('.prediction-table').addClass('sticky');

            // prevent flicker
            $('.table-content').addClass('js-extra-padding');

        } else {
            $('.prediction-table').removeClass('sticky');
            $('.table-content').removeClass('js-extra-padding');
        }
    }
};

$(document).ready(function () {
	if (!$(".prediction-wrapper").length) {
        console.log('false');
        return;
    } else {
        console.log('true');
    }
    /* default settings */
    $('#predictions-links li, #content-list > li, .table-content .person, #overview').removeClass('active');

    // here 100 is height of navbar + prediction-table (mobile)
    let preditionTableOffsetTop = $('.prediction-table').offset().top - 100;

    //let furtherReadOffsetTop = $('#further_read').offset().top;

    const tableContent = $('#toggle-table-content');
    const contentList = $('#content-list');

    let adjustOfsetTop = 0;

    if (device.mobile) {
        adjustOfsetTop = 60;
    } else {
        adjustOfsetTop = 30;
    }

    /** For: Mobile view
     * toggle table content list */
    $(tableContent).on('click', function () {

        $(this).find('.fa').toggleClass('fa-angle-up');
        $(contentList).toggleClass('show');

    });

    /** For: predictions-links
     * Handle active sate
     * Smooth scroll
     */
    $('#predictions-links li').on('click', function (event) {

        event.preventDefault();

        // reset
        $(contentList).removeClass('show');
        $(tableContent).find('.fa').removeClass('fa-angle-up');

        $('#content-list > li').removeClass('active');
        $('#predictions-links li').removeClass('active');
        $(this).addClass('active');

        let target = $(this).find('a').attr('href');
        $('html, body').animate({
            scrollTop: $(target).offset().top - adjustOfsetTop
        }, 800);

    });

    $('#content-list > li > a').on('click', function (event) {

        event.stopPropagation();

        // reset
        $(contentList).removeClass('show');
        $(tableContent).find('.fa').removeClass('fa-angle-up');

        $('#predictions-links li').removeClass('active');
        $('#content-list > li').removeClass('active');
        $(this).parent('li').addClass('active');

        let target = $(this).attr('href');

        console.log(target);

        $('html, body').animate({
            scrollTop: $(target).offset().top - adjustOfsetTop
        }, 800);

    });

    /* scroll */
    $(window).on('scroll', function () {

        let scrollTop = $(this).scrollTop();

        //prediction.handleScroll(scrollTop, furtherReadOffsetTop);

        prediction.handleScroll(scrollTop);
        prediction.stickyTableContents(scrollTop, preditionTableOffsetTop);

    });
});
