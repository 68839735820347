/* eslint-disable */
(function ($) {
	$('.aed-new-page .use-case .icon-play').magnificPopup({
		disableOn: 300,
		type: 'iframe',
		mainClass: 'mfp-fade',
		removalDelay: 160
	});
}(jQuery));


/* all devices */
let device = {
	mobile: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
};

/* sticky header strip changes */
let navbarBrandLogo = $(".page-aed-new .logo-container .bhw-widget img");
if (!navbarBrandLogo.length) {
	console.log('if')
	navbarBrandLogo = $(".aed-page-header .logo-container .bhw-widget img");
}
let navbarBrandLogoSrc = $(navbarBrandLogo).attr("src");
let stripHeaderLogoSrc = $(".strip__header > img").attr('src');
/* end sticky header strip changes */

const aedNewPage = {

	onTargetClick: function () {

		if (!$('#target-nav-list').length) {
			return;
		}

		let link = $('.aed-new-page a[data-target]');

		$(link).on('click', function (event) {

			event.preventDefault();

			// Remove active class from all links.
			link.removeClass('active');

			// Add active class from current link.
			$(this).addClass('active');

			// Get target.
			let target = $('#' + $(this).data('target'));

			if (target.length) {

				event.preventDefault();

				$('html, body').animate({
					scrollTop: target.offset().top - 80
				}, 750);

			}

		});
	},
	onPageScroll: function (navListOffSetTop, ww) {

		/* sticky header strip changes */
		let siteHeader = $(".navigation-v2--desktop");
		/* end sticky header strip changes */

		let targetNavList = $('#target-nav-list');

		if (!targetNavList.length) {
			return;
		}

		let scrollPosition = $(document).scrollTop();

		let links = $(targetNavList).find('a[data-target]');

		if (!links.length) {
			return;
		}
		if (scrollPosition > navListOffSetTop) {
			$(targetNavList).addClass('fixed');
			$(siteHeader).addClass("remove-sticky");
		} else if (scrollPosition < navListOffSetTop) {
			$(targetNavList).removeClass('fixed');
			$(siteHeader).removeClass("remove-sticky");
		}
		/* sticky header strip changes */
		if (ww < 767) {
			if (scrollPosition > navListOffSetTop) {
				$(navbarBrandLogo).attr("src", stripHeaderLogoSrc);
				$(navbarBrandLogo).css("max-width", '80px');
			} else if (scrollPosition < navListOffSetTop) {
				$(navbarBrandLogo).attr("src", navbarBrandLogoSrc);
				$(navbarBrandLogo).css("max-width", '100%');
			}
		}


		links.each(function () {
			let currentLink = $(this);
			let refElement = $('#' + currentLink.data('target'));
			if (!refElement) {
				return;
			}

			if (refElement.position().top - 85 <= scrollPosition && refElement.position().top + refElement.height() > scrollPosition) {
				links.removeClass('active');
				currentLink.addClass('active').removeClass('past');
			} else {
				currentLink.removeClass('active');
			}

			if (refElement.position().top + refElement.height() >= scrollPosition + 85) {
				currentLink.removeClass('past');
			} else {
				currentLink.addClass('past');
			}

		});

	},
	onEngageMentClick: function () {
		let tab = $(".no-tabs__tab");
		let output = $(".output__data");
		let outputMob = $(".output--mobile");
		let ww = $(window).width();

		$(tab).on("click", function () {
			$(tab).removeClass('active');
			$(this).addClass('active');

			let index = $(this).index();
			$(output).hide();
			$(output).eq(index).show();

		});

		// for mobile only
		if (ww < 767) {
			$(tab).on("click", function () {
				$(tab).removeClass('active');
				$(this).addClass('active');
			});
		}
	},
}

$(document).ready(function () {

	if (!$(".aed-new-page").length && !$(".page-aed-new").length && !$(".page-new-aed").length) {
		return;
	}

	aedNewPage.onTargetClick();
	let targetNavListOffSetTop = $('#target-nav-list').offset().top;
	let navHeight = $(".navigation-v2--desktop").height();
	let navListOffSet, ww = $(window).width();

	if (ww >= 768) {
		navListOffSet = targetNavListOffSetTop; // - navHeight;
	} else {
		navListOffSet = targetNavListOffSetTop - navHeight;
	}

	/* scroll */
	$(window).on('scroll', function () {
		aedNewPage.onPageScroll(navListOffSet, ww);
	});

	// onEngageMentClick
	aedNewPage.onEngageMentClick();
	//header sticky menu

	var e = $(".intersect__items .item").length, t = 3;
	$(".intersect__items .item:lt(" + t + ")").show(), $("#loadMores_aed").click(function (a) {
		//alert();
		a.preventDefault(), t = t + 3 <= e ? t + 3 : e, $(".intersect__items .item:lt(" + t + ")").show(), $("html,body").animate({ scrollTop: "+=300px" }), $(".intersect__items .item:visible").length === e && $("#loadMores_aed").hide()
	});

});

$(document).ready(function () {
	let e;
	$(".intersect__items .item .inner.cab-agenda").each(function () {
		e = e > $(this).height() ? e : $(this).height();
	}), $(".intersect__items .item .inner.cab-agenda").each(function () {
		$(this).height(e)
	})
});


