import '../sass/clabs.scss';
import './clab/swiper';
import './clab/prediction';
import './clab/rfp';
import './clab/aide';
import './clab/aed';
import './clab/new-aed';
import './clab/core-of-cab';
import './clab/landing-page';
import './clab/navbar-v3';
import './clab/new-template-case-study';
import './clab/streamflux';

//import './clab/job-opening-api';
