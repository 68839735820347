(function ($) {

	/* eslint-disable */

	$(".lp-accordion .lp-accordion__header").on("click", function () {
		$(this).toggleClass('active');
		$(".lp-accordion .lp-accordion__body").toggle();
	});

}(jQuery));
