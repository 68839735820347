
$(document).ready(function () {
	/* eslint-disable */
	if ($(".streamflux").length > 0) {
		//pov case study
		$('.video-thumbnail .icon-play').magnificPopup({
			disableOn: 300,
			type: 'iframe',
			mainClass: 'mfp-fade',
			removalDelay: 160
		});
	}
});
